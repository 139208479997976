import { useEffect } from "react";
import { useRouter } from "next/router";
import { getCookie, setCookie } from "main/javascripts/utils/cookieUtil";
import { UTM_COOKIE_EXPIRATION_DAYS } from "main/javascripts/constants/SearchConstants";
import { useAppDispatch } from "main/javascripts/store";
import { setIsTrackingParameterStored } from "main/javascripts/slices/paramSlice";
import {
  TrackingPageType,
  TrackingPageTypes,
} from "../../constants/TrackingPageTypes";
import { fetchCvValue } from "main/javascripts/slices/accountParamSlice";

export const useTrackingParameter = ({
  pageType = TrackingPageTypes.default,
  params,
}: {
  pageType?: TrackingPageType;
  params?: {
    postId?: number;
    postTags?: string[];
  };
} = {}) => {
  const router = useRouter();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (router.isReady) {
      appDispatch(setIsTrackingParameterStored(false));
      const query = router.query;
      const acType = query.ac_type;
      let utmSource = query.utm_source;
      let utmMedium = query.utm_medium;
      let utmCampaign = query.utm_campaign;
      let utmContent = query.utm_content;
      let utmTerm = query.utm_term;
      const tSessionId = query.t_session_id;
      const gclid = query.gclid;
      const wbraid = query.wbraid;
      const gbraid = query.gbraid;
      const yclid = query.yclid;
      const msclkid = query.msclkid;
      const ttclid = query.ttclid;
      const fbclid = query.fbclid;
      const twclid = query.twclid;
      let shouldRequestCvValue = false;

      if (pageType === TrackingPageTypes.post) {
        const [
          defaultUtmSource,
          defaultUtmMedium,
          defaultUtmCampaign,
          defaultUtmContent,
          defaultUtmTerm,
        ] = getDefaultPostUtmParameters(params);
        if (!utmSource) {
          utmSource = defaultUtmSource;
        }
        if (!utmMedium) {
          utmMedium = defaultUtmMedium;
        }
        if (!utmCampaign) {
          utmCampaign = defaultUtmCampaign;
        }
        if (!utmContent) {
          utmContent = defaultUtmContent;
        }
        if (!utmTerm) {
          utmTerm = defaultUtmTerm;
        }
      }

      if (acType) {
        setCookie("ac_type", acType, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmSource && !getCookie("utm_source")) {
        shouldRequestCvValue = true;
        setCookie("utm_source", utmSource, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmMedium && !getCookie("utm_medium")) {
        shouldRequestCvValue = true;
        setCookie("utm_medium", utmMedium, UTM_COOKIE_EXPIRATION_DAYS);
      }
      // utmContentとutmTermはutm_campaignがあれば更新しない
      if (utmContent && !getCookie("utm_campaign")) {
        shouldRequestCvValue = true;
        setCookie("utm_content", utmContent, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmTerm && !getCookie("utm_campaign")) {
        setCookie("utm_term", utmTerm, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (utmCampaign && !getCookie("utm_campaign")) {
        shouldRequestCvValue = true;
        setCookie("utm_campaign", utmCampaign, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (tSessionId) {
        setCookie("t_session_id", tSessionId, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (gclid) {
        setCookie("gclid", gclid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (wbraid) {
        setCookie("wbraid", wbraid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (gbraid) {
        setCookie("gbraid", gbraid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (yclid) {
        setCookie("yclid", yclid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (msclkid) {
        setCookie("msclkid", msclkid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (ttclid) {
        setCookie("ttclid", ttclid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (fbclid) {
        setCookie("fbclid", fbclid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      if (twclid) {
        setCookie("twclid", twclid, UTM_COOKIE_EXPIRATION_DAYS);
      }
      appDispatch(setIsTrackingParameterStored(true));
      if (shouldRequestCvValue) {
        appDispatch(fetchCvValue());
      }
    }
  }, [router.isReady]);
  return;
};

const getDefaultPostUtmParameters = (params) => {
  const referrer = document.referrer;
  let utmSource = "";
  let utmMedium = "";
  if (referrer.includes("google")) {
    utmSource = "google";
    utmMedium = "organic";
  } else if (referrer.includes("yahoo")) {
    utmSource = "yahoo";
    utmMedium = "organic";
  } else if (referrer.includes("bing")) {
    utmSource = "bing";
    utmMedium = "organic";
  } else if (referrer.includes("instagram")) {
    utmSource = "instagram";
    utmMedium = "organic";
  } else if (referrer.includes("youtube")) {
    utmSource = "youtube";
    utmMedium = "organic";
  } else {
    utmSource = "other";
    utmMedium = "organic";
  }

  const utmCampaign = process.env.HOSTING_DOMAIN ? "line_ta1" : "post";

  const utmContent = params?.postId;

  let utmTerm = "";
  if (params?.postTags && params?.postTags.length > 0) {
    utmTerm = params.postTags.join(";");
  }

  return [utmSource, utmMedium, utmCampaign, utmContent, utmTerm];
};
